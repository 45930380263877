@charset "utf-8";

/**************************************************
   reset CSS
**************************************************/
.text,
.txt01,
.txt02,
.txt03,
.txt04,
.txt05,
.Text,
.text02,
.Text03,
.TextBox,
.SubText,
.StoryTitle,
.StoryText,
.CharacterText,
.MotifText,
.Title01,
.SmallText,
.ServiceText {
  white-space: pre-line;
}
.blueText {
  color: #607fff;
}
.strongText {
  font-family: "NSKB";
}
html,
body {
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: #222222;
  -webkit-text-size-adjust: none;
  overflow-y: hidden;
}
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
table,
th,
td,
form,
fieldset,
legend,
input,
textarea,
img,
button,
select {
  margin: 0;
  padding: 0;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
button {
  font-family: "NSKR", "돋움", "Dotum", "Helvetica", "Apple SD Gothic Neo",
    "sans-serif";
}
img,
fieldset {
  border: 0;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  color: #222222;
  text-decoration: none;
}
em,
address {
  font-style: normal;
}
ul,
ol,
li {
  list-style: none;
}
label,
button {
  cursor: pointer;
}
input::-ms-clear {
  display: none;
}
input:disabled {
  -webkit-text-fill-color: #8d8d8d;
  opacity: 1;
}
iframe {
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 0;
}

* {
  -webkit-overflow-scrolling: touch;
} /* 모바일에서 터치 부드러운 효과 적용 */

/* Noto Sans */
@font-face {
  font-family: "NSKR";
  src: url("../font/NotoSans/NotoSans-Regular.eot") format("embedded-opentype"),
    url("../font/NotoSans/NotoSans-Regular.otf") format("opentype"),
    url("../font/NotoSans/NotoSans-Regular.woff") format("woff"),
    url("../font/NotoSans/NotoSans-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "NSKB";
  src: url("../font/NotoSans/NotoSans-Bold.eot") format("embedded-opentype"),
    url("../font/NotoSans/NotoSans-Bold.otf") format("opentype"),
    url("../font/NotoSans/NotoSans-Bold.woff") format("woff"),
    url("../font/NotoSans/NotoSans-Bold.woff2") format("woff2");
}

/**************************************************
    Common
**************************************************/
.accessibility {
  overflow: hidden;
  position: absolute;
  left: -1000px;
  top: 0;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
  text-indent: -9999px;
}
.hidden {
  overflow: hidden;
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 1px;
  height: 1px;
}
.blind {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
}

.skip {
  display: none;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
}
.no_ellipsis {
  white-space: normal !important;
}

.ovh {
  overflow: hidden !important;
}
.ovv {
  overflow: visible !important;
}

/* flex */
.h-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  min-width: 0;
  min-height: 0;
}
.v-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
}
.flex-1 {
  -webkit-flex: 1;
  flex: 1;
  min-width: 0;
  min-height: 0;
}
.flex-2 {
  -webkit-flex: 2;
  flex: 2;
  min-width: 0;
  min-height: 0;
}
.flex-3 {
  -webkit-flex: 3;
  flex: 3;
  min-width: 0;
  min-height: 0;
}

/*scroll*/
.scroll_on {
  overflow: auto !important;
}
.scroll_off {
  overflow: hidden !important;
}
.scroll_y_on {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.scroll_x_on {
  overflow-y: hidden !important;
  overflow-x: auto !important;
}
.scroll_fix {
  overflow: scroll !important;
}
.scroll_y_fix {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}
.scroll_x_fix {
  overflow-y: hidden !important;
  overflow-x: scroll !important;
}

/*position*/
.posi_re {
  position: relative !important;
}
.posi_ab {
  position: absolute !important;
}
.posi_fix {
  position: fixed !important;
}
.posi_inh {
  position: inherit !important;
}
.posi_stk {
  position: sticky !important;
}

/* Floating */
.fl {
  float: left !important;
}
.fr {
  float: right !important;
}
.cl {
  clear: both !important;
}

/*각 자식요소들 floating 지정으로 높이 인식못하는거 해결*/
.clear:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

/* border none */
.brn {
  border: none !important;
}
.brtn {
  border-top: none !important;
}
.brbn {
  border-bottom: none !important;
}
.brln {
  border-left: none !important;
}
.brrn {
  border-right: none !important;
}

/* display */
.dp_ib {
  display: inline-block !important;
}
.dp_i {
  display: inline !important;
}
.dp_b {
  display: block !important;
}
.dp_n {
  display: none !important;
}

/* visibility */
.vv {
  visibility: visible;
}
.vh {
  visibility: hidden;
}

/* Align */
.al {
  text-align: left !important;
}
.ac {
  text-align: center !important;
}
.ar {
  text-align: right !important;
}

.vt {
  vertical-align: top !important;
}
.vm {
  vertical-align: middle !important;
}
.vb {
  vertical-align: bottom !important;
}

/* Font size */
.f11 {
  font-size: 11px !important;
}
.f12 {
  font-size: 12px !important;
}
.f13 {
  font-size: 13px !important;
}
.f14 {
  font-size: 14px !important;
}
.f15 {
  font-size: 15px !important;
}
.f16 {
  font-size: 16px !important;
}
.f17 {
  font-size: 17px !important;
}
.f18 {
  font-size: 18px !important;
}
.f19 {
  font-size: 19px !important;
}
.f20 {
  font-size: 20px !important;
}
.upper {
  text-transform: uppercase;
}

/* Font weight */
.fwb {
  font-weight: bold !important;
}
.fwn {
  font-weight: normal !important;
}

/* Padding, Margin style */
/* 특별한 case외에 사용 자제 */
.p0 {
  padding: 0 !important;
}
.p10 {
  padding: 10px !important;
}
.p15 {
  padding: 15px !important;
}
.p20 {
  padding: 20px !important;
}

.pt0 {
  padding-top: 0 !important;
}
.pt5 {
  padding-top: 5px !important;
}
.pt10 {
  padding-top: 10px !important;
}
.pt15 {
  padding-top: 15px !important;
}
.pt20 {
  padding-top: 20px !important;
}
.pt25 {
  padding-top: 25px !important;
}
.pt30 {
  padding-top: 30px !important;
}
.pt35 {
  padding-top: 35px !important;
}
.pt40 {
  padding-top: 40px !important;
}
.pt45 {
  padding-top: 45px !important;
}
.pt50 {
  padding-top: 50px !important;
}

.pb0 {
  padding-bottom: 0 !important;
}
.pb5 {
  padding-bottom: 5px !important;
}
.pb10 {
  padding-bottom: 10px !important;
}
.pb15 {
  padding-bottom: 15px !important;
}
.pb20 {
  padding-bottom: 20px !important;
}
.pb25 {
  padding-bottom: 25px !important;
}
.pb30 {
  padding-bottom: 30px !important;
}
.pb35 {
  padding-bottom: 35px !important;
}
.pb40 {
  padding-bottom: 40px !important;
}
.pb45 {
  padding-bottom: 45px !important;
}
.pb50 {
  padding-bottom: 50px !important;
}

.pl0 {
  padding-left: 0px !important;
}
.pl5 {
  padding-left: 5px !important;
}
.pl10 {
  padding-left: 10px !important;
}
.pl15 {
  padding-left: 15px !important;
}
.pl20 {
  padding-left: 20px !important;
}
.pl25 {
  padding-left: 25px !important;
}
.pl30 {
  padding-left: 30px !important;
}
.pl35 {
  padding-left: 35px !important;
}
.pl40 {
  padding-left: 40px !important;
}
.pl45 {
  padding-left: 45px !important;
}
.pl50 {
  padding-left: 50px !important;
}

.pr0 {
  padding-right: 0px !important;
}
.pr5 {
  padding-right: 5px !important;
}
.pr10 {
  padding-right: 10px !important;
}
.pr15 {
  padding-right: 15px !important;
}
.pr20 {
  padding-right: 20px !important;
}
.pr25 {
  padding-right: 25px !important;
}
.pr30 {
  padding-right: 30px !important;
}
.pr35 {
  padding-right: 35px !important;
}
.pr40 {
  padding-right: 40px !important;
}
.pr45 {
  padding-right: 45px !important;
}
.pr50 {
  padding-right: 50px !important;
}

.m0 {
  margin: 0px !important;
}
.m5 {
  margin: 5px !important;
}
.m10 {
  margin: 10px !important;
}
.m15 {
  margin: 15px !important;
}
.m20 {
  margin: 20px !important;
}

.mt0 {
  margin-top: 0px !important;
}
.mt5 {
  margin-top: 5px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt15 {
  margin-top: 15px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt25 {
  margin-top: 25px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt35 {
  margin-top: 35px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt45 {
  margin-top: 45px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mt120 {margin-top: 120px !important;}

.mb0 {
  margin-bottom: 0px !important;
}
.mb5 {
  margin-bottom: 5px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb15 {
  margin-bottom: 15px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb25 {
  margin-bottom: 25px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mb35 {
  margin-bottom: 35px !important;
}
.mb40 {
  margin-bottom: 40px !important;
}
.mb45 {
  margin-bottom: 45px !important;
}
.mb50 {
  margin-bottom: 50px !important;
}

.ml0 {
  margin-left: 0px !important;
}
.ml5 {
  margin-left: 5px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.ml15 {
  margin-left: 15px !important;
}
.ml20 {
  margin-left: 20px !important;
}
.ml25 {
  margin-left: 25px !important;
}
.ml26 {
  margin-left: 26px !important;
}
.ml30 {
  margin-left: 30px !important;
}
.ml35 {
  margin-left: 35px !important;
}
.ml40 {
  margin-left: 40px !important;
}
.ml45 {
  margin-left: 45px !important;
}
.ml50 {
  margin-left: 50px !important;
}

.mr0 {
  margin-right: 0px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.mr15 {
  margin-right: 15px !important;
}
.mr20 {
  margin-right: 20px !important;
}
.mr25 {
  margin-right: 25px !important;
}
.mr30 {
  margin-right: 30px !important;
}
.mr35 {
  margin-right: 35px !important;
}
.mr40 {
  margin-right: 40px !important;
}
.mr45 {
  margin-right: 45px !important;
}
.mr50 {
  margin-right: 50px !important;
}
